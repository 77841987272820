@font-face {
  font-family: 'FontKuran';
  src: url('/KuranKerimFontLatif.ttf');
}
@font-face {
  font-family: 'Mushaf';
  src: url('/Mushaf.ttf');
}
@font-face {
  font-family: 'FontKuranVersion3';
  src: url('/KuranKerimFontAbay.ttf');
}

.page {
  text-align: center;
  margin: 10px 0;
}
.image {
  margin: 10px 0px;
  width: 100%;
}

.page-input {
  width: 300px;
}

.sure {
  list-style: none;
}
ul {
  padding: 0;
}
.cursor-pointer {
  cursor: pointer;
}

.dark-body {
  background-color: #1b2836;
}

.dark-body * {
  color: #d8d8d8 !important;
}

.dark-body {
  input {
    background-color: #1b2836 !important;
  }
  textarea {
    background-color: #1b2836 !important;
  }

  .foot-nav {
    background: #1b2836;

    input {
      border: 1px solid #484545;
    }
  }

  hr {
    border-top: 1px solid rgba(255, 250, 250, 0.28);
  }
  select.bg-white,
  select,
  .rc-time-picker-panel * {
    background: #1b2836 !important;
  }
  .list-group-item-action:hover {
    background-color: #1b2836 !important;
  }
}

.stats-table {
  max-height: 350px;
  overflow: auto;
  font-size: 14px;
  border-top: 2px dashed rgb(26, 23, 23);
  border-bottom: 2px dashed rgb(0, 0, 0);
}

.foot-nav {
  height: 60px;
  margin-top: 10px;
  background: #bbe6ae;
  i {
    font-size: 22px;
  }

  input {
    width: 65px;
    text-align: center;
    border-radius: 4px;
  }
}

.jumbo {
  background: linear-gradient(rgb(188, 245, 170), rgb(255, 255, 255));
}
.dark-body .jumbo {
  background: linear-gradient(#1b2836, rgb(56, 81, 109)) !important;
}
.dark-body .bg-white {
  background: linear-gradient(#203244, rgb(45, 65, 87)) !important;
}
.dark-body .border {
  border: unset !important;
}
.bg-light {
  background: linear-gradient(#c5eeccc4, white) !important;
  border-bottom: rgb(188, 245, 170) 0.5px solid;
}
.dark-body .bg-light {
  background: linear-gradient(#1b2836, rgb(56, 83, 110)) !important;
  border-bottom: #1b2836 0.5px solid;
}
.dark-body .react-toast-notifications__toast__content {
  color: black !important;
}
.dark-body .react-toast-notifications__toast__dismiss-button {
  background-color: #1b2836;
}
.dark-body .recharts-tooltip-wrapper * span {
  color: black !important;
}
.dark-body .list-group-item {
  background-color: #213346;
  margin: 3px 0;
  text-align: center;
  border-color: #283c3c;

  &:hover {
    background: transparent;
  }
}

.nav-link {
  text-align: center;
}

.gizle-goster-butonu {
  position: absolute;
  cursor: pointer;
  font-size: 0.7rem;
  top: -14px;
  right: 43px;
}

@media only screen and (min-width: 992px) {
  .geriButonu {
    display: none;
  }
  .image {
    max-width: 600px;
  }
}
@media only screen and (max-width: 992px) {
  /* .geriButonu {
		display: none;
	} */
  .image {
    max-width: 100%;
  }
}

/* .home-buttons {
	display: flex;
	align-content: space-between;
	justify-content: space-around;
	flex-wrap: wrap;
}
.home-buttons > div > a{
} */

.text-box {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.text-box > a {
  display: block;
  font-size: 1.1em;
  font-weight: 600;
  text-decoration: none;
  color: #000000;
  background: rgba(238, 238, 157, 0.473) !important;

  padding: 15px;
  min-width: 300px;
  margin: 1.2%;
  min-height: 70px;
}
.text-box > a:hover {
  background: rgb(241, 221, 105) !important;
}
.dark-body .text-box > a:hover {
  background: linear-gradient(#355474, rgb(47, 67, 88)) !important;
}

.min-height {
  min-height: 80vh;
}

h1 {
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;
}

@media only screen and (max-width: 992px) {
  h1 {
    font-size: 20px;
  }
}

/* The Modal (background) */
.my-modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 20vh; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.my-modal-content {
  background-color: #5a87b3;
  color: black;
  margin: auto;
  padding: 20px;
  border: 1px solid #5a87b3;
  width: 80%;
  border-radius: 5px;
}

/* The Close Button */
.my-modal .close {
  color: black;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.my-modal .close:hover,
.my-modal .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

#bodycik > .container {
  min-height: calc(100vh - 185px);
}

.list-container {
  li {
    list-style: none !important;
  }
}

.dark-mode-img {
  background-blend-mode: normal;
  filter: saturate(2) brightness(0.9) contrast(1.1) hue-rotate(170deg) invert(1);
}
