body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.counter {
  width: 200px;
  height: 200px;
  background-color: rgb(152, 208, 68);
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto 2px auto;
  cursor: pointer;
}
.dark-body .counter {
  background-color: #4f89ca;
}
.counter small {
  font-size: 25px;
  font-weight: 600;
}
.zikir-container small,
.zikir-container span {
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.Collapsible {
  margin: 2px 0;
  border-radius: 10px;
}
.collapsible-header {
  background: rgb(197, 236, 138);
  margin-bottom: 10px;
}
.dark-body .collapsible-header {
  background: #36506d;
  margin-bottom: 10px;
}

@font-face {
  font-family: 'FontKuran';
  src: url('/KuranKerimFontLatif.ttf');
}

.arabca {
  font-family: 'Mushaf', serif;
  direction: rtl;
  font-size: 38px !important;
  text-align: center;
}
.celce-meal {
  color: #860a15;
}
.dark-body .celce-meal {
  color: #e0b29f !important;
}

.cevsen-last-row .arabca {
  color: rgb(202, 69, 69) !important;
}

.cevsen-hr {
  margin: 0;
  height: 1px;
  background-color: #e2dcdc;
}
.dark-body .cevsen-hr {
  margin: 0;
  height: 1px;
  background-color: #424242a1;
}
